import React, { useEffect, useState } from "react";

import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import ConditionalField from "shared/components/common/Conditional/ConditionalField";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import ClaimApproveAction from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/header/claims/ClaimApproveAction";
import ClaimRejectAction from "internal/programs/migration-acceleration-program/2024/fund-request/components/details/header/claims/ClaimRejectAction";
import { Button } from "@amzn/awsui-components-react";
import {
  CASH_CLAIM_STAGE,
  CASH_CLAIM_STATUS,
} from "shared/util/constants/fundRequestStatusType";
import { useHistory } from "react-router-dom";
import { STAGE_MAPPING } from "internal/util/constants/pages";
import { SECTION_NAME } from "shared/util/constants/section";
import { isFeatureEnabled } from "shared/util/services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "shared/util/constants/featureFlagValues";

export interface CashClaimActionsHeaderProps {
  data: IGenericObject;
  claim: IGenericObject;
  loading: boolean;
}

const CashClaimActionsHeader = ({
  data,
  claim,
  loading,
}: CashClaimActionsHeaderProps) => {
  const [isInternalUploadFeatureEnabled, setIsInternalUploadFeatureEnabled] =
    useState<boolean>(false);

  useEffect(() => {
    const checkInternalUploadFlag = async () => {
      const internalUploadFlag = await isFeatureEnabled(
        FEATURE_FLAG_ENUMS.INTERNAL_ATTACHMENT_UPLOAD_PARTNERS_BEHALF
      );
      if (internalUploadFlag) {
        setIsInternalUploadFeatureEnabled(true);
      }
    };
    checkInternalUploadFlag();
  }, []);

  const history = useHistory();
  if (!data || !claim) return <></>;

  const VALID_CASH_CLAIMS_STAGES_INTERNAL_USER_UPLOAD_ATTACHMENTS = [
    CASH_CLAIM_STAGE.PARTNER,
    CASH_CLAIM_STAGE.INVOICE,
    CASH_CLAIM_STAGE.CLAIM_APPROVAL,
    CASH_CLAIM_STAGE.COMPLETED,
  ];
  const VALID_CASH_CLAIMS_STATUSES_INTERNAL_USER_UPLOAD_ATTACHMENTS = [
    CASH_CLAIM_STATUS.UNLOCKED,
    CASH_CLAIM_STATUS.LOCKED,
    CASH_CLAIM_STATUS.ACTIVE,
    CASH_CLAIM_STATUS.SUBMITTED,
    CASH_CLAIM_STATUS.COMPLETED,
  ];

  const frid = data.fundRequestId;
  const claimId = claim.claimId;
  const isClaimActive = claim.claimStatus === CASH_CLAIM_STATUS.ACTIVE;
  const isClaimStatusValid =
    VALID_CASH_CLAIMS_STATUSES_INTERNAL_USER_UPLOAD_ATTACHMENTS.includes(
      claim.claimStatus
    );
  const isClaimStageValid =
    VALID_CASH_CLAIMS_STAGES_INTERNAL_USER_UPLOAD_ATTACHMENTS.includes(
      claim.claimStage
    );
  const path = STAGE_MAPPING[data.stage as keyof typeof STAGE_MAPPING];

  return (
    <>
      <SpaceBetween size="l" direction="horizontal">
        <ConditionalField
          showField={
            isInternalUploadFeatureEnabled &&
            isClaimStatusValid &&
            isClaimStageValid
          }
        >
          <Button
            variant="primary"
            onClick={() =>
              history.push(
                `/${path}/${frid}/${SECTION_NAME.ATTACHMENTS}/${claimId}/attachments/edit`,
                { claimId: claimId }
              )
            }
          >
            Upload Claim Attachments
          </Button>
        </ConditionalField>
        <ConditionalField
          showField={
            isClaimActive &&
            claim.claimStage === CASH_CLAIM_STAGE.CLAIM_APPROVAL
          }
        >
          <ClaimApproveAction claimId={claimId} loading={loading} />
        </ConditionalField>
        <ConditionalField
          showField={
            isClaimActive &&
            claim.claimStage === CASH_CLAIM_STAGE.CLAIM_APPROVAL
          }
        >
          <ClaimRejectAction claimId={claimId} loading={loading} data={data} />
        </ConditionalField>
        <ConditionalField
          showField={
            isClaimActive && claim.claimStage === CASH_CLAIM_STAGE.INVOICE
          }
        >
          <Button
            variant="primary"
            onClick={() =>
              history.push(`/map2024/${frid}/${claimId}/claim-invoice/edit`)
            }
          >
            Submit invoice
          </Button>
        </ConditionalField>
      </SpaceBetween>
    </>
  );
};

export default CashClaimActionsHeader;
